<template>
  <span class="px-3">
    <EditOnClick v-model="value" type="price" :editable="row.editable" />
    <button
      class="btn btn-secondary btn-sm"
      :title="$t(`refundMoney.refundMoney`)"
      v-on:click="showRefund"
      v-if="value?.amount > 0"
    >
      <BaseIcon name="money-send" color="var(--black)" />
    </button>
  </span>
</template>
<script>
import { defineAsyncComponent } from "vue";
import EditOnClick from "@/components/inputs/EditOnClick";
const BaseIcon = defineAsyncComponent(() => import("../../icons/BaseIcon.vue"));

export default {
  name: "RefundMoney",
  components: { EditOnClick, BaseIcon },
  props: {
    id: Number,
    type: String,
    row: Object,
    modelValue: Object,
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    value() {
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    modelValue() {
      this.value = this.modelValue;
    },
  },
  methods: {
    showRefund() {
      window.apps.callEvent("modal:sendMoney", this.id);
    },
  },
  emits: ["update:modelValue", "change"],
};
</script>
